import priscilla_escaping_chaos from './short/priscilla_escaping_chaos.mp3'
import emilyWITHerin_from_disablity_to_superpower from './short/emily&erin_from_disablity_to_superpower.mp3'
import lisa_quahogging from './short/lisa_quahogging.mp3'
import nacy_a_teaching_moment from './short/nacy_a_teaching_moment.mp3'
import ben_dad_goals from './short/ben_dad_goals.mp3'
import poppy_becoming_a_cape_pharmacist from './short/poppy_becoming_a_cape_pharmacist.mp3'
import evan_current_motivations from './short/evan_current_motivations.mp3'
import nacy_being_a_switchboard_operator from './short/nacy_being_a_switchboard_operator.mp3'
import grammy_painting from './short/grammy_painting.mp3'
import marissa_becoming_a_mom from './short/marissa_becoming_a_mom.mp3'
import ben_childhood_anxiety from './short/ben_childhood_anxiety.mp3'
import kacy_creating_memories from './short/kacy_creating_memories.mp3'
import emily_remembering_key_largo from './short/emily_remembering_key_largo.mp3'
import benWITHmarissa_spirituality from './short/ben&marissa_spirituality.mp3'
import evan_thank_you from './short/evan_thank_you.mp3'
import tenley_an_eye_opening_moment from './short/tenley_an_eye_opening_moment.mp3'
import david_destressing from './short/david_destressing.mp3'
import poppy_the_first_george_denmark from './short/poppy_the_first_george_denmark.mp3'
import lisa_joyful_elders from './short/lisa_joyful_elders.mp3'
import tenley_college_goals from './short/tenley_college_goals.mp3'
import grammy_future_generations from './short/grammy_future_generations.mp3'
import erin_emilys_birth_story from './short/erin_emilys_birth_story.mp3'
import grammy_her_dad from './short/grammy_her_dad.mp3'
import poppy_gardening from './short/poppy_gardening.mp3'
import emily_a_day_long_date from './short/emily_a_day_long_date.mp3'
import lisa_meeting_david from './short/lisa_meeting_david.mp3'
import poppy_denmarks_bar from './short/poppy_denmarks_bar.mp3'
import nacy_the_medical_field from './short/nacy_the_medical_field.mp3'
import grammy_the_end_of_life from './short/grammy_the_end_of_life.mp3'
import nacy_a_hype_reel from './short/nacy_a_hype_reel.mp3'
import nacy_her_mission from './short/nacy_her_mission.mp3'
import david_a_changing_church from './short/david_a_changing_church.mp3'
import ben_cape_cod from './short/ben_cape_cod.mp3'
import marissa_her_papa from './short/marissa_her_papa.mp3'
import david_sacredness from './short/david_sacredness.mp3'
import nacy_church from './short/nacy_church.mp3'
import grammyWITHpoppy_their_first_child from './short/grammy&poppy_their_first_child.mp3'
import priscilla_uncle_johns_beard from './short/priscilla_uncle_johns_beard.mp3'
import marissa_highs_and_lows from './short/marissa_highs_and_lows.mp3'
import ben_sibling_superpowers from './short/ben_sibling_superpowers.mp3'
import tenley_how_she_changed_in_HS from './short/tenley_how_she_changed_in_HS.mp3'
import erin_Ben from './short/erin_Ben.mp3'
import lisa_taking_opie_out from './short/lisa_taking_opie_out.mp3'
import davidWITHpriscilla_family_business from './short/david&priscilla_family_business.mp3'
import benWITHmarissa_family_business from './short/ben&marissa_family_business.mp3'
import priscilla_the_early_days from './short/priscilla_the_early_days.mp3'
import nacy_the_first_tv from './short/nacy_the_first_tv.mp3'
import nacy_being_an_entertainer from './short/nacy_being_an_entertainer.mp3'
import evan_being_in_hawaii from './short/evan_being_in_hawaii.mp3'
import erin_finding_OT from './short/erin_finding_OT.mp3'
import priscillaWITHgrammy_the_other_aunt_priscilla from './short/priscilla&grammy_the_other_aunt_priscilla.mp3'
import lisa_cape_summers from './short/lisa_cape_summers.mp3'
import tim_a_crazy_party from './short/tim_a_crazy_party.mp3'
import lisa_trying_new_things from './short/lisa_trying_new_things.mp3'
import josh from './storyslam/josh.mp3'
import liam from './storyslam/liam.mp3'
import lisa from './storyslam/lisa.mp3'
import maxcy from './storyslam/maxcy.mp3'
import annMarie from './storyslam/annMarie.mp3'
import beth from './storyslam/beth.mp3'
import david from './storyslam/david.mp3'
import allie from './storyslam/allie.mp3'
import evan from './storyslam/evan.mp3'
import andrew from './storyslam/andrew.mp3'


export const shortAudio = [
 { speaker: 'Priscilla', topic: 'escaping chaos', link:priscilla_escaping_chaos},
 { speaker: 'Emily & Erin', topic: 'from disablity to superpower', link:emilyWITHerin_from_disablity_to_superpower},
 { speaker: 'Lisa', topic: 'quahogging', link:lisa_quahogging},
 { speaker: 'Nacy', topic: 'a teaching moment', link:nacy_a_teaching_moment},
 { speaker: 'Ben', topic: 'dad goals', link:ben_dad_goals},
 { speaker: 'Poppy', topic: 'becoming a cape pharmacist', link:poppy_becoming_a_cape_pharmacist},
 { speaker: 'Evan', topic: 'current motivations', link:evan_current_motivations},
 { speaker: 'Nacy', topic: 'being a switchboard operator', link:nacy_being_a_switchboard_operator},
 { speaker: 'Grammy', topic: 'painting', link:grammy_painting},
 { speaker: 'Marissa', topic: 'becoming a mom', link:marissa_becoming_a_mom},
 { speaker: 'Ben', topic: 'childhood anxiety', link:ben_childhood_anxiety},
 { speaker: 'Kacy', topic: 'creating memories', link:kacy_creating_memories},
 { speaker: 'Emily', topic: 'remembering key largo', link:emily_remembering_key_largo},
 { speaker: 'Ben & Marissa', topic: 'spirituality', link:benWITHmarissa_spirituality},
 { speaker: 'Evan', topic: 'thank you', link:evan_thank_you},
 { speaker: 'Tenley', topic: 'an eye opening moment', link:tenley_an_eye_opening_moment},
 { speaker: 'David', topic: 'destressing', link:david_destressing},
 { speaker: 'Poppy', topic: 'the first george denmark', link:poppy_the_first_george_denmark},
 { speaker: 'Lisa', topic: 'joyful elders', link:lisa_joyful_elders},
 { speaker: 'Tenley', topic: 'college goals', link:tenley_college_goals},
 { speaker: 'Grammy', topic: 'future generations', link:grammy_future_generations},
 { speaker: 'Erin', topic: 'emilys birth story', link:erin_emilys_birth_story},
 { speaker: 'Grammy', topic: 'her dad', link:grammy_her_dad},
 { speaker: 'Poppy', topic: 'gardening', link:poppy_gardening},
 { speaker: 'Emily', topic: 'a day long date', link:emily_a_day_long_date},
 { speaker: 'Lisa', topic: 'meeting david', link:lisa_meeting_david},
 { speaker: 'Poppy', topic: 'denmarks bar', link:poppy_denmarks_bar},
 { speaker: 'Nacy', topic: 'the medical field', link:nacy_the_medical_field},
 { speaker: 'Grammy', topic: 'the end of life', link:grammy_the_end_of_life},
 { speaker: 'Nacy', topic: 'a hype reel', link:nacy_a_hype_reel},
 { speaker: 'Nacy', topic: 'her mission', link:nacy_her_mission},
 { speaker: 'David', topic: 'a changing church', link:david_a_changing_church},
 { speaker: 'Ben', topic: 'cape cod', link:ben_cape_cod},
 { speaker: 'Marissa', topic: 'her papa', link:marissa_her_papa},
 { speaker: 'David', topic: 'sacredness', link:david_sacredness},
 { speaker: 'Nacy', topic: 'church', link:nacy_church},
 { speaker: 'Grammy & Poppy', topic: 'their first child', link:grammyWITHpoppy_their_first_child},
 { speaker: 'Priscilla', topic: 'uncle johns beard', link:priscilla_uncle_johns_beard},
 { speaker: 'Marissa', topic: 'highs and lows', link:marissa_highs_and_lows},
 { speaker: 'Ben', topic: 'sibling superpowers', link:ben_sibling_superpowers},
 { speaker: 'Tenley', topic: 'how she changed in HS', link:tenley_how_she_changed_in_HS},
 { speaker: 'Erin', topic: 'Ben', link:erin_Ben},
 { speaker: 'Lisa', topic: 'taking opie out', link:lisa_taking_opie_out},
 { speaker: 'David & Priscilla', topic: 'family business', link:davidWITHpriscilla_family_business},
 { speaker: 'Ben & Marissa', topic: 'family business', link:benWITHmarissa_family_business},
 { speaker: 'Priscilla', topic: 'the early days', link:priscilla_the_early_days},
 { speaker: 'Nacy', topic: 'the first tv', link:nacy_the_first_tv},
 { speaker: 'Nacy', topic: 'being an entertainer', link:nacy_being_an_entertainer},
 { speaker: 'Evan', topic: 'being in hawaii', link:evan_being_in_hawaii},
 { speaker: 'Erin', topic: 'finding OT', link:erin_finding_OT},
 { speaker: 'Priscilla & Grammy', topic: 'the other aunt priscilla', link:priscillaWITHgrammy_the_other_aunt_priscilla},
 { speaker: 'Lisa', topic: 'cape summers', link:lisa_cape_summers},
 { speaker: 'Tim', topic: 'a crazy party', link:tim_a_crazy_party},
 { speaker: 'Lisa', topic: 'trying new things', link:lisa_trying_new_things},
];


export const fullAudio = [
];


export const storyslamAudio = [
 { speaker: 'Josh', topic: '', link:josh},
 { speaker: 'Liam', topic: '', link:liam},
 { speaker: 'Lisa', topic: '', link:lisa},
 { speaker: 'Maxcy', topic: '', link:maxcy},
 { speaker: 'Annmarie', topic: '', link:annMarie},
 { speaker: 'Beth', topic: '', link:beth},
 { speaker: 'David', topic: '', link:david},
 { speaker: 'Allie', topic: '', link:allie},
 { speaker: 'Evan', topic: '', link:evan},
 { speaker: 'Andrew', topic: '', link:andrew},
];
